import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

// Create a context
const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [userId, setUserId] = useState(null);
	const [accessToken, setAccessToken] = useState(null);
	const [isLoggedIn, setIsLoggedIn] = useState(false);

	const logIn = ({ token, uId }) => {
		setAccessToken(token);
		setUserId(uId);
		setIsLoggedIn(true);
	};

	const logOut = () => {
		Cookies.remove('sprintfolio-a-t');
		Cookies.remove('sprintfolio-uid');
		setAccessToken(null);
		setUserId(null);
		setIsLoggedIn(false);
	};

	useEffect(() => {
		const token = Cookies.get('sprintfolio-a-t');
		const uid = Cookies.get('sprintfolio-uid');

		if (token && uid) {
			setAccessToken(token);
			setUserId(uid);
			setIsLoggedIn(true);
		} else {
			setIsLoggedIn(false);
		}
	}, []);

	return (
		<UserContext.Provider
			value={{ userId, accessToken, isLoggedIn, logIn, logOut }}>
			{children}
		</UserContext.Provider>
	);
};

// Hook to use the context
export const useUser = () => useContext(UserContext);

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import CaseStudy from './components/CaseStudy/CaseStudy';
import ResetPassword from './components/ResetPassword/ResetPassword';
import SignUp from './components/SignUp/SignUp';
import Projects from './components/Projects/Projects';
import Completed from './components/Dashboard/Completed';
import { UserProvider } from './contexts/UserContext';

function App() {
	return (
		<UserProvider>
			<Router>
				<Routes>
					<Route
						path="/"
						element={<Login />}
					/>
					<Route
						path="/dashboard"
						element={<Dashboard />}
					/>
					<Route 
						path="/projects" 
						element={<Projects />} />
					<Route 
						path="/completed" 
						element={<Completed/>} />
					<Route
						path="/reset-password"
						element={<ResetPassword />}
					/>
					<Route
						path="/signup"
						element={<SignUp />}
					/>
					<Route path="/CaseStudy/:projectId" element={<CaseStudy />} />
				</Routes>
			</Router>
		</UserProvider>
	);
}

export default App;
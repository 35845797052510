// Login.jsx
import React, { useEffect } from 'react';
import './Login.css';
import Cookies from 'js-cookie';
import { API_BASE_URL } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

function Login() {
	const navigate = useNavigate();

	const { logIn, isLoggedIn } = useUser();

	const handleLogin = async (event) => {
		event.preventDefault();

		localStorage.setItem('isLoggedIn', true); 



		try {
			const response = await fetch(`${API_BASE_URL}/login`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					email: event.target.email.value,
					password: event.target.password.value,
				}),
			});

			const json = await response.json();

			if (json.success) {
				Cookies.set('sprintfolio-a-t', json.data.token, { expires: 7 });
				Cookies.set('sprintfolio-uid', json.data.user_id, { expires: 7 });

				logIn({
					token: json.data.token,
					uId: json.data.user_id,
				});

				navigate('/dashboard');
			} else {
				console.log('Login failed', json.message);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	useEffect(() => {
		if (isLoggedIn) {
			navigate('/dashboard');
		}
	}, [isLoggedIn, navigate]);

	return (
		<div className="login-container">
			<h1>AI Career Accelerator for Designers</h1>
			<h3>personalized, and hands-on learning for every designer out there</h3>
			<div className="container">
				<form onSubmit={handleLogin}>
					<input
						type="email"
						id="email"
						name="email"
						placeholder="Email"
						required
					/>
					<input
						type="password"
						id="password"
						name="password"
						placeholder="Password"
						required
					/>
					<button
						className="button login-button"
						type="submit">
						Login
					</button>
				</form>
				{/* <p>
					Don't have an account? <a href="/signup">Signup</a>
				</p> */}
				<p>
					Forgot your password? <a href="/reset-password"> Reset Here</a>
				</p>
			</div>
		</div>
	);
}
export default Login;
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    background-image: url('https://prospero0.s3.us-east-2.amazonaws.com/website/sprintfolio_background_1x.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #FFFAF4;
    font-family: Arial, sans-serif;
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    margin: 0;
}

input[type="email"], 
input[type="password"] {
    display: flex;
    width: 456px;
    height: 63px;
    padding: 0px 20px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid rgba(138, 138, 138, 0.52);
    background: #FFF;
    margin: 10px auto; /* Centering and adding some vertical spacing */
}

.container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
}

form {
    margin-top: 20px;
}

.login-button {
    border-radius: 12px;
    height: 42px;
    width: 100px;
    background: #FFF;
}




@media (max-width: 767px) {
    .two-column {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,4GAA4G;IAC5G,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,yBAAyB;IACzB,8BAA8B;IAC9B,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,SAAS;AACb;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,SAAS;IACT,cAAc;IACd,mBAAmB;IACnB,2CAA2C;IAC3C,gBAAgB;IAChB,iBAAiB,EAAE,+CAA+C;AACtE;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;;;;;AAKA;IACI;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".login-container {\n    background-image: url('https://prospero0.s3.us-east-2.amazonaws.com/website/sprintfolio_background_1x.webp');\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    background-color: #FFFAF4;\n    font-family: Arial, sans-serif;\n    padding: 30px;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    height: 100vh;\n    margin: 0;\n}\n\ninput[type=\"email\"], \ninput[type=\"password\"] {\n    display: flex;\n    width: 456px;\n    height: 63px;\n    padding: 0px 20px;\n    align-items: center;\n    gap: 10px;\n    flex-shrink: 0;\n    border-radius: 12px;\n    border: 1px solid rgba(138, 138, 138, 0.52);\n    background: #FFF;\n    margin: 10px auto; /* Centering and adding some vertical spacing */\n}\n\n.container {\n    max-width: 500px;\n    margin: 0 auto;\n    padding: 20px;\n}\n\nform {\n    margin-top: 20px;\n}\n\n.login-button {\n    border-radius: 12px;\n    height: 42px;\n    width: 100px;\n    background: #FFF;\n}\n\n\n\n\n@media (max-width: 767px) {\n    .two-column {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task9() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 9">
      <p>Task 9: Style Guide</p>
            <ul>Use this template to create a style guide for this project. Write up some bullet points explaining your choice for the mood board and brand personality.
                </ul>
            <ul>For Example:
                <li>The style guide for our crypto tool emphasizes a modern aesthetic with cool blues and grays for trustworthiness. Clear typography ensures easy navigation, creating a consistent and user-friendly visual experience.
                </li>
          </ul>
       
      </CollapsibleButton>
    </div>
  );
}

export default Task9;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-summary-edit {
    font-family: "League Spartan";
    font-size: 16px;
    flex: 1 1; /* Let textarea grow to fill available space */
    resize: none; /* Disable resizing */
    border-radius: 20px;
    padding: 20px; /* Add padding */
    border: 1px solid #ccc; /* Add top border */
    background-color: white; /* Make background transparent */
    width: 100%; /* Make textarea width 100% */
    box-sizing: border-box; /* Include padding and border in the width */
    resize: both;
}

`, "",{"version":3,"sources":["webpack://./src/components/CaseStudy/TextEditor/TextEditor.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,eAAe;IACf,SAAO,EAAE,8CAA8C;IACvD,YAAY,EAAE,qBAAqB;IACnC,mBAAmB;IACnB,aAAa,EAAE,gBAAgB;IAC/B,sBAAsB,EAAE,mBAAmB;IAC3C,uBAAuB,EAAE,gCAAgC;IACzD,WAAW,EAAE,6BAA6B;IAC1C,sBAAsB,EAAE,4CAA4C;IACpE,YAAY;AAChB","sourcesContent":[".user-summary-edit {\n    font-family: \"League Spartan\";\n    font-size: 16px;\n    flex: 1; /* Let textarea grow to fill available space */\n    resize: none; /* Disable resizing */\n    border-radius: 20px;\n    padding: 20px; /* Add padding */\n    border: 1px solid #ccc; /* Add top border */\n    background-color: white; /* Make background transparent */\n    width: 100%; /* Make textarea width 100% */\n    box-sizing: border-box; /* Include padding and border in the width */\n    resize: both;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

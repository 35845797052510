import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task5() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 5">
        <p>Task 5: Personas
        <ul> User Personas: : Analyze your notes and draft 2-3 user personas. 
          <li>Write 2-4 sentences about your process and discoveries.</li>
        <li>Example: IMAGE</li>
        </ul>
        </p>
      </CollapsibleButton>
    </div>
  );
}

export default Task5;

import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task11() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 11">
        <p>Task 11: Hi-Fi Wireframes
        <ul>Create high-fidelity wireframes, incorporating detailed UI elements, color schemes, and typography. Write a summary of your process.
        <li>Example: IMAGE</li>
        </ul>
        </p>
      </CollapsibleButton>
    </div>
  );
}

export default Task11;

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { API_BASE_URL } from '../../constants';
import './CaseStudy.css';
import Navbar from '../Nav/NavBar';
import TextEditor from './TextEditor/TextEditor';
import Task1 from '../Tasks/Task1';
import Task2 from '../Tasks/Task2';
import Task3 from '../Tasks/Task3';
import Task4 from '../Tasks/Task4';
import Task5 from '../Tasks/Task5';
import Task6 from '../Tasks/Task6';
import Task7 from '../Tasks/Task7';
import Task8 from '../Tasks/Task8';
import Task9 from '../Tasks/Task9';
import Task10 from '../Tasks/Task10';
import Task11 from '../Tasks/Task11';
import Task12 from '../Tasks/Task12';
import Task13 from '../Tasks/Task13';
import Task14 from '../Tasks/Task14';
import Lio1 from '../Lio1/Lio1';



const CaseStudy = () => {
    const navigate = useNavigate();

    const { projectId } = useParams(); 
    const [projectData, setProjectData] = useState(null);
    const { userId, accessToken, isLoggedIn } = useUser();
    // eslint-disable-next-line
    const [userName, setUserName] = useState(null);
    // eslint-disable-next-line
    const [loadingProjects, setLoadingProjects] = useState(true);
    const [projects, setProjects] = useState([]);


    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get('reset-token');
    console.log(resetToken);

    const fetchUser = async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/user/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`, // include this in every request to pass access token to server
                },
            });
            const json = await response.json();
            const { first_name, last_name } = json.data;
            setUserName(`${first_name} ${last_name}`);
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const fetchProjects = async () => {
        try {
            console.log('fetching projects');
            const response = await fetch(`${API_BASE_URL}/user/${userId}/projects`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`, // include this in every request to pass access token to server
                },

            });
            const { data } = await response.json();
            const { projects } = data;
            projects.forEach(project => {
                project.tasks.forEach(task => {
                    project[`template_${task.template_id}`] = task;
                    
                }
                );
                console.log(project);
            });

            setLoadingProjects(false);
            setProjects(projects);
            console.log('Project Data:', JSON.stringify(projects, null, 2));
        } catch (error) {
            console.error('Error:', error);
        }
    };


    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
        } else {
            fetchUser();
            fetchProjects();
        }
        // eslint-disable-next-line
    }, [navigate, accessToken, isLoggedIn, userId]);



    useEffect(() => {
        // eslint-disable-next-line
        projects.map((project) => {
            console.log(`we are in project: ${project.id} in projects`)
            if (project.id === Number(projectId)) {
                console.log(`projectData found: ${project.problem_statement}`)
                setProjectData(project)
            }
        })

    }, [projects, projectId]);



    if (!projectData) {
        return <div>Loading project details...</div>;
    };

    const renderTextWithoutLink = (text) => {
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        return text.replace(regex, '$1');
    };


    const renderObjectiveWithLink = (text) => {
        // This function splits the text into parts and converts markdown links to anchor elements
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        let parts = text.split(regex);
        return parts.map((part, index) => {
            // Every third element of the array is a URL, with the preceding element being the link text
            if (index % 3 === 1) {
                return <a href={parts[index + 1]} target="_blank" rel="noopener noreferrer" key={index}>{part}</a>;
            }
            // Text that is not part of a markdown link should only be returned on every third match to avoid duplication
            if (index % 3 === 0) {
                return part;
            }
            return null;
        });
    };

    const handleImageUpload = async (event, taskTemplateId) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('coverImage', file);

      await   fetch(`${API_BASE_URL}/project/${projectId}/tasks/${taskTemplateId}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
    })
        event.target.value = null;

        fetchProjects();
    };






    return (
        <div className='casestudy_container'>
            <Navbar />
            <div className="header_container">
                <div className="title_submit_container">
                    <h2>Case Study + Project Building:  {projectData.project_template.client}</h2>
                    {/* submit for review button */}
                    <button
                        className='button submitreview_button'
                        onClick={async () => {
                            try {
                                const response = await fetch(`${API_BASE_URL}/project/${projectId}/request-review`, {
                                    method: 'PUT',
                                    headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                    body: JSON.stringify({ reviewStatus: "Requested" }),
                                });

                                if (!response.ok) {
                                    throw new Error(`HTTP error! Status: ${response.status}`);
                                }

                                const data = await response.json();
                                console.log('Review request submitted:', data);
                                // Optionally, add any UI feedback here, e.g., a success message
                                alert('Review request successfully submitted.');
                            } catch (error) {
                                console.error('Error submitting review request:', error);
                                // Optionally, add any UI feedback here, e.g., an error message
                                alert('Failed to submit review request.');
                            }
                        }}
                    >
                        Submit for Review
                    </button>
                </div>
                <div className='title_container'>
                <h4>{projectData.project_template.title}</h4>
                </div>
                <div className="boxes">
                    <h5>Company Background</h5>
                    <p> {renderTextWithoutLink(projectData.project_template.company_overview)}
                    </p>
                </div>
                <div className="two_box">
                    <div className="row">
                        <div className="row_boxes problem_solution_box">
                            <h5>Objective</h5>
                            <p> {renderObjectiveWithLink(projectData.project_template.objective)}
                            </p>

                        </div>
                        {/* Solution */}
                        <div className="row_boxes problem_solution_box">
                            <h5>Problem Statement</h5>
                            <TextEditor id={projectId} val={projectData.problem_statement} name={'problem_statement'} />
                        </div>
                    </div>
                </div>
                <div className="steps_container">
                        <img
                            src={require('../../assest/design_thinking.png')}
                            href="!#"
                            alt="Task Flow"
                        />
                    </div>
            </div>
            {/* EMPATHYZE SECTION */}
            <div className="title_container">
                <h6>Empathize</h6>
            </div>
            {/* Secondary Research*/}
            <div className="boxes">
                <div className="img_container">
                    { projectData.template_1?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_1?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 1)}
                    />
                <b>Secondary Research</b>
                <Task1 />
                <TextEditor id={projectId} val={projectData.template_1?.summary} name={'secondary_research'} taskTemplateId={1} />
            </div>
                   {/* INTERVIEW SECTION */}
                   <div className="boxes">
                <div className="img_container">
                { projectData.template_2?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_2?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 2)}
                    />
                <b>User Interviews</b>
                <Task2 />
                <TextEditor id={projectId} val={projectData.template_2?.summary} name={'user_interviews'} taskTemplateId={2}/>
            </div>
            {/* DEFINE SECTION */}
            <div className="title_container">
                <h6>Define</h6>
            </div>
            {/* Affinity Map */}
            <div className='two_box'>
                <div className="row">
                    <div className="row_boxes">
                        <div className="img_container">
                        { projectData.template_3?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_3?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 3)}
                    />
                        <b>Affinity Map</b>
                        <Task3 />
                        <TextEditor id={projectId} val={projectData.template_3?.summary} name={'affinity_map'} taskTemplateId={3} />

                    </div>
                    {/* Empathy Map */}
                    <div className="row_boxes">
                        <div className="img_container">
                        { projectData.template_4?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_4?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 6)}
                    />
                        <b>Empathy Map</b>
                        <Task4 />
                        <TextEditor id={projectId} val={projectData.template_4?.summary} name={'empathy_map'} taskTemplateId={4}/>
                    </div>
                </div>
            </div>
            {/* PERSONAS */}
            <div className="boxes">
                <div className="img_container">
                { projectData.template_5?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_5?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 5)}
                    />
                <b>Personas</b>
                <Task5 />
                <TextEditor id={projectId} val={projectData.template_5?.summary} name={'personas'} taskTemplateId={5}/>
            </div>

            {/* USER STORIES */}
            <div className="boxes">
                <div className="img_container">
                { projectData.template_6?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_6?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 6)}
                    />
                <b>User Stories</b>
                <Task6/>
                <TextEditor id={projectId} val={projectData.template_6?.summary} name={'user_stories'} taskTemplateId={6}/>
            </div>
            {/* IDEATION SECTION */}
            <div className="title_container">
                <h6>Ideation</h6>
            </div>
            {/* USERFLOW SECTION */}
            <div className="boxes">
                <div className="img_container">
                { projectData.template_7?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_7?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 7)}
                    />
                <b>User Flow</b>
                <Task7 />
                <TextEditor id={projectId} val={projectData.template_7?.summary} name={'user_flow'} taskTemplateId={7}/>
            </div>
                 {/* MOOD BOARD*/}
                 <div className='two_box'>
                <div className="row">
                    <div className="row_boxes problem_solution_box">
                        <div className="img_container">
                        { projectData.template_8?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_8?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 8)}
                    />
                        <b>Mood Board</b>
                        <Task8/>
                        <TextEditor id={projectId} val={projectData.template_8?.summary} name={'mood_board'} taskTemplateId={8}/>
                    </div>
                    {/* STYLE GUIDE */}
                    <div className="row_boxes problem_solution_box">
                        <div className="img_container">
                        { projectData.template_11?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_9?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 9)}
                    />
                        <b>Style Guide</b>
                        <Task9/>
                        <TextEditor id={projectId} val={projectData.template_9?.summary} name={'style_guide'} taskTemplateId={9}/>
                    </div>
                </div>
            </div>
            {/* WIREFRAMES */}
            <div className="boxes">
                <div className="img_container">
                { projectData.template_10?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_10?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 10)}
                    />
                <b>Wireframes</b>
                <Task10/>
                <TextEditor id={projectId} val={projectData.template_10?.summary} name={'wireframes'} taskTemplateId={10}/>
            </div>
                {/* HI-FI WIREFRAMES */}
                <div className="boxes">
                <div className="img_container">
                { projectData.template_11?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_11?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 12)}
                    />
                <b>Hi-Fi Wireframes</b>
                <Task11/>
                <TextEditor id={projectId} val={projectData.template_11?.summary} name={'wireframes'} taskTemplateId={11}/>
            </div>
            {/* PROTOTYPE SECTION */}
            <div className="title_container">
                <h6>Prototype</h6>
            </div>
            <div className="boxes">
                <div className="img_container">
                { projectData.template_12?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_12?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 12)}
                    />
                <b>Prototype</b>
                <Task12/>
                <TextEditor id={projectId} val={projectData.template_12?.summary} name={'prototype'} taskTemplateId={12}/>
            </div>
            <div className="title_container">
                <h6>Tests</h6>
            </div>
             {/* USER TESTING */}
             <div className="boxes">
                <div className="img_container">
                { projectData.template_13?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_13?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 13)}
                    />
                <b>User Testing</b>
                <Task13/>
                <TextEditor id={projectId} val={projectData.template_13?.summary} name={'reflections'} taskTemplateId={13}/>
            </div>
            <div className="title_container">
                <h6>Reflections</h6>
            </div>
            {/* FINAL REFLECTIONS */}
            <div className="boxes">
                <div className="img_container">
                { projectData.template_14?.cover_image && (
                        <img src={'https://sprintfolio.s3.us-east-2.amazonaws.com/'+ projectData.template_14?.cover_image} alt='cover' />
                    )}
                </div>
                    <input
                        className='input-file'
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(e, 14)}
                    />
                <b>Final Reflections</b>
                <Task14/>
                <TextEditor id={projectId} val={projectData.template_14?.summary} name={'reflections'} taskTemplateId={14}/>
            </div>   
            <h6 className='footer'>
                    This project is powered by Sprintfolio | 2024
                </h6>
            <Lio1 />
        </div>


    );
};

export default CaseStudy;


import React, { useState, useEffect } from 'react';
import './TextEditor.css';
import CopyAlert from '../../CopyAlert/CopyAlert';
import { API_BASE_URL } from '../../../constants';
import { useUser } from '../../../contexts/UserContext';

const TextEditor = ({ id, val, name, taskTemplateId }) => {
    const [summary, setSummary] = useState('');
    const { accessToken } = useUser();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (val !== "") {
            setSummary(val)
        }
    }, [val]);

    useEffect(() => {
        const saveSummary = async () => {
            try {
                let formData = new FormData();
                let apiUrl = `${API_BASE_URL}/project/${id}/tasks/${taskTemplateId}`;
                let headers = {
                    'Authorization': `Bearer ${accessToken}`,
                };

                if (name === "problem_statement") {
                    apiUrl = `${API_BASE_URL}/project/${id}`;
                    formData = JSON.stringify({ 'problemStatement': summary });
                    headers['Content-Type'] = 'application/json';
                } else {
                    formData.append('taskSummary', summary);
                }

                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    headers: headers,
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                console.log('Successfully updated:', data);
            } catch (error) {
                console.error('Error updating:', error);
            }
        };

        // Save summary whenever it changes
        if (summary) {
            saveSummary();
        }
    }, [summary, id, taskTemplateId, accessToken, name]);

    const handleSummaryChange = (event) => {
        const text = event.target.value;
        setSummary(text);
    };

    const handleCopyAll = () => {
        navigator.clipboard.writeText(summary)
            .then(() => {
                console.log('Text copied to clipboard');
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 5000);
            })
            .catch((error) => {
                console.error('Error copying text: ', error);
            });
    };

    return (
        <div>
            <textarea
                className='user-summary-edit'
                value={summary ? summary : ""}
                onChange={handleSummaryChange}
                placeholder={'Write your research here'}
                onClick={(event) => event.stopPropagation()}
                rows={3}
                cols={28}
            />
            <button className='copy-all' onClick={handleCopyAll}>
                Copy All
            </button>
            <CopyAlert message={copied ? 'Copied to clipboard' : ''} />
        </div>
    );
};

export default TextEditor;

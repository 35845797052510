import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './Lio1.css';
import AI from '../../assest/LioAI.svg';
import Logo from '../../assest/LioLogo.svg';
import { API_BASE_URL } from '../../constants';
import { useUser } from '../../contexts/UserContext';
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaArrowAltCircleUp } from "react-icons/fa";


const Lio1 = () => {
    const navigate = useNavigate();
    const chatHistoryRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [userName, setUserName] = useState(null);
    // eslint-disable-next-line
    const [loadingProjects, setLoadingProjects] = useState(true);
    // eslint-disable-next-line
    const [projects, setProjects] = useState([]);
    const { userId, accessToken, isLoggedIn } = useUser();
    const [announcementVisible, setAnnouncementVisible] = useState(true);
    const [lastAssistantMessage, setLastAssistantMessage] = useState(null);

    const togglePopup = () => {
        setIsOpen(!isOpen);
        setAnnouncementVisible(false); // Add this line
    };


    useEffect(() => {
        const timer = setTimeout(() => {
            setAnnouncementVisible(false);
        }, 5000); // 5 seconds

        return () => clearTimeout(timer);
    }, []);

    const fetchProjects = async () => {
        try {
            console.log('fetching projects');
            const response = await fetch(`${API_BASE_URL}/user/${userId}/projects`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`, // include this in every request to pass access token to server
                },
            });
            const { data } = await response.json();
            const { projects } = data;
            setLoadingProjects(false);
            setProjects(projects);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchUser = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/user/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`, // include this in every request to pass access token to server
                },
            });
            const json = await response.json();
            const { first_name, last_name } = json.data;
            setUserName(`${first_name} ${last_name}`);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
        } else {
            fetchUser();
            fetchProjects();
        }
         // eslint-disable-next-line
    }, [navigate, accessToken, isLoggedIn, userId]);

    const sendMessage = async () => {
        if (!message.trim()) return; // Prevent sending empty messages

        try {
            const userMessage = {
                content: message,
                role: 'user',
                created_at: Math.floor(Date.now() / 1000),
            };
            // Log the user's message to the chat history before sending
            const updatedChatHistory = [...chatHistory, userMessage];
            setChatHistory(updatedChatHistory);

            // Store the chat history in localStorage
            localStorage.setItem('chatHistory', JSON.stringify(updatedChatHistory));

            setMessage(''); // Clear the message input immediately after updating chat history

            const response = await fetch(`${API_BASE_URL}/messages`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    userMessage: message,
                    projectTemplateId: '1' // Adjust according to your needs
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            const responseData = await response.json(); // Assuming the server responds with JSON
            console.log('Message sent successfully:', responseData);

            // Append the response message to the chat history
            const responseMessage = {
                content: responseData.data.message, // Extract the message content from responseData
                role: 'assistant',
                created_at: Math.floor(Date.now() / 1000),
            };
            const updatedChatHistoryWithResponse = [...updatedChatHistory, responseMessage];
            setChatHistory(updatedChatHistoryWithResponse);

            // Store the updated chat history in localStorage
            localStorage.setItem('chatHistory', JSON.stringify(updatedChatHistoryWithResponse));
            setLastAssistantMessage(responseMessage);
            
        } catch (error) {
            console.error('Error sending message:', error);
            alert('Error sending message: ' + error.message);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            sendMessage();
        }
    };

    useEffect(() => {
        // Retrieve chat history from localStorage on component mount
        const storedChatHistory = localStorage.getItem('chatHistory');
        if (storedChatHistory) {
            setChatHistory(JSON.parse(storedChatHistory));
        }
    }, []);

    useEffect(() => {
        if (isOpen && chatHistoryRef.current) {
            if (lastAssistantMessage) {
                const lastAssistantMessageIndex = chatHistory.findIndex(item => item === lastAssistantMessage);
                if (lastAssistantMessageIndex !== -1) {
                    chatHistoryRef.current.scrollTop = lastAssistantMessageIndex * 100; // Adjust 100 as needed
                }
            } else {
                chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
            }
        }
    }, [isOpen, chatHistory, lastAssistantMessage]);
    

    const getUserInitials = () => {
        if (userName) {
            const [firstName, lastName] = userName.split(' ');
            return firstName.charAt(0) + (lastName ? lastName.charAt(0) : '');
        }
        return '';
    };


    



    return (
        <div>
            <button className="open-chat" onClick={togglePopup}>
                <div className="image-container">
                    <img src={AI} alt="AI" />
                </div>

                {announcementVisible && ( // Conditionally render the announcement chat bubble
                    <div className="chat-bubble">
                        <p> I am Lio, your AI Assistant! Feel free to ask questions or chat with me.</p>
                    </div>
                )}

            </button>

            {isOpen && (
                <div className="popup">
                    <div className="popup-header">
                        <img src={Logo} alt="logo" width={45} height={45} />
                        <span>Chat with Lio</span>
                        <div className="close" onClick={togglePopup}>
                            <IoCloseCircleOutline className='icons' />
                        </div>
                    </div>
                    <div className="popup-content">
                        <div className="chat-history" ref={chatHistoryRef}>
                            {chatHistory.map((item, index) => (
                                <div key={index} className={`message ${item.role}`}>
                                    {/* Add a conditional rendering to apply different styles for user and assistant messages */}
                                    {item.role === 'user' ? (
                                        <div className="user-message">
                                            <span className="user-initials">
                                                {getUserInitials()}
                                            </span>
                                            <p>: {item.content}</p>
                                        </div>
                                    ) : (
                                        <div className="assistant-message">
                                            <p>
                                                <img src={AI} alt="Assistant Logo" className="assistant-logo" /> : {item.content}</p>
                                        </div>

                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="message-container">
                            <div style={{ position: 'relative' }}>
                                <textarea
                                    className="lio_textarea"
                                    placeholder="Type your message here..."
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    onKeyDown={handleKeyPress} // Add event listener for key press
                                    rows={3}
                                    cols={28}
                                ></textarea>
                                <FaArrowAltCircleUp
                                    className='send-button'
                                    onClick={sendMessage}
                                    style={{
                                        position: 'absolute',
                                        right: '20px',
                                        bottom: '30px'
                                    }} />

                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Lio1;

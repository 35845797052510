import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task7() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 7">
          <p>Task 7: User Flow</p>
            <ul>Based on the brainstorming session, sketch out user flows for the primary features. Use a tool like FigJam to lays out the user's movement through the product, mapping out each and every step the user takes. This is your user flows.
                </ul>
            <ul>For Example:
                <li>This user flow for customizing coffee orders in a subscription app outlines the journey from logging in to confirming an order change. It details each interaction step, such as selecting preferences and reviewing changes, using a tool like FigJam for visual mapping. This approach ensures a clear, intuitive path for users, highlighting potential friction points for early resolution, and streamlining the design and development process.
                </li>
            <li>Example: IMAGE</li>
          </ul>
       
      </CollapsibleButton>
    </div>
  );
}

export default Task7;

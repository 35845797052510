import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ResetPassword.css';
import { useUser } from '../../contexts/UserContext';
import { API_BASE_URL } from '../../constants';

function ResetPassword() {
	const [email, setEmail] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [resetToken, setResetToken] = useState(null);
	const navigate = useNavigate();

	const { logIn } = useUser();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!resetToken) {
			try {
				const response = await fetch(`${API_BASE_URL}/request-password-reset`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ email }),
				});
				const json = await response.json();

				if (json.success) {
					setSuccess(json.message);
					alert(json.message);
				} else {
					setError(json.message);
				}
			} catch (error) {
				console.error('Error:', error);
			}
		}

		try {
			const response = await fetch(
				`${API_BASE_URL}/reset-password/${resetToken}`,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ email, newPassword }),
				}
			);
			const json = await response.json();

			if (json.success) {
				setSuccess(json.message);
				logIn({
					token: json.data.token,
					uId: json.data.user_id,
				});
				navigate('/dashboard');
			} else {
				setError(json.message);
			}
		} catch (error) {
			console.error('Error:', error);
		}
	};

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const token = params.get('reset-token');
		setResetToken(token);
	}, []);

	return (
		<div className='reset_container'>
			<h1>{resetToken ? 'Reset Password' : 'Request Password Reset'}</h1>
			<form onSubmit={handleSubmit}>
				<label htmlFor="email">Please enter the email address that associated with your account.</label>
				<input
				placeholder='Email'
					type="email"
					id="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				{resetToken && (
					<>
						<input
							placeholder='New Password'
							type="password"
							id="email"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
						/>
					</>
				)}
				<button 
				className='button submit-button'
				type="submit">Submit</button>
				{resetToken && (
					<button
					className='button newreset-button'
						type="submit"
						onClick={() => {
							setResetToken(null);
							navigate('/reset-password');
						}}>
						Request new reset email
					</button>
				)}
			</form>
			{error && <p className="error">{error}</p>}
			{success && <p className="success">{success}</p>}
		</div>
	);
}

export default ResetPassword;

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './NavDashBoard.css';

function NavDashBoard() {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const location = useLocation();
    const [activeButton, setActiveButton] = useState('Active Sprints'); // Set initial active button

    useEffect(() => {
        // Set active button based on the current URL path
        const path = location.pathname;
        if (path === '/Dashboard') {
            setActiveButton('Active Sprints');
        } else if (path === '/completed') {
            setActiveButton('Completed Sprints');
        } else if (path === '/projects') {
            setActiveButton('Find New Sprints');
        } else {
            // Default to 'Active Sprints' if none of the above paths match
            setActiveButton('Active Sprints');
        }
    }, [location]);

    const navigateToProjects = () => {
        navigate('/projects');
    };

    const navigateToDashboard = () => {
        navigate('/Dashboard');
    };

    const navigateToCompletedSprints = () => {
        navigate('/completed');
    };

    return (
        <div className="nav-dash">
            <button 
                className={`nav-dash-button ${activeButton === 'Active Sprints' ? 'active' : ''}`}
                onClick={navigateToDashboard} >Active Sprints</button>
            <button
                className={`nav-dash-button ${activeButton === 'Completed Sprints' ? 'active' : ''}`}
                onClick={navigateToCompletedSprints} >Completed Sprints</button>
            <button
                className={`nav-dash-button ${activeButton === 'Find New Sprints' ? 'active' : ''}`}
                onClick={navigateToProjects} >Find New Sprints</button>
        </div>
    );
}

export default NavDashBoard;

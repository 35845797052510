// Navbar.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import './NavBar.css';

function Navbar() {
	const navigate = useNavigate(); // Initialize useNavigate hook
	const { logOut } = useUser(); // Initialize useUser hook

	
	const handleLogout = () => {
		logOut(); // Call logOut function from useUser hook
		navigate('/'); // Redirect to login page
	};


	return (
		<div className="navbar">
			<a href="https://sprintfolio.com/">About</a>
			<a href="/dashboard">My Dashboard</a>
			<a href="https://discord.gg/JWQDshUReZ">Discord</a>
			<a href="https://form.typeform.com/to/DBbVv1uJ">Send Feedback</a>
			<a href="#/" onClick={handleLogout}>Logout</a>
		</div>
	);
}

export default Navbar;
import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task14() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 14">
      <p>Task 14: Reflections</p>
            <ul> Write up a short paragraph reflecting on the entire process, mention what you discovered, what you learned and anything significant.
                </ul>
            <ul>For Example:
                <li>This case study concludes our journey in designing a customizable chatbot for the educational sector, 
                    aimed at fostering better communication between educators and students. 
                    Leveraging insights from research and interviews, 
                    we developed a user-centric chatbot that combines AI for real-time assistance and personalized learning experiences. 
                    The process, from wireframes to prototypes, underscored the importance of empathy in design, showcasing our chatbot's potential to transform educational interactions. 
                    This project not only reaffirms our dedication to innovative educational solutions but also highlights the impact of design thinking in enhancing virtual learning environments.
                </li>
          </ul>
       
      </CollapsibleButton>
    </div>
  );
}

export default Task14;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
    position: fixed;
    bottom: -50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4CAF50;
    color: white;
    padding: 15px;
    border-radius: 10px;
    transition: transform 0.5s, bottom 0.5s;
}

.alert.show {
    bottom: 20px;
    transform: translateX(-50%);
}
`, "",{"version":3,"sources":["webpack://./src/components/CopyAlert/CopyAlert.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,SAAS;IACT,2BAA2B;IAC3B,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,YAAY;IACZ,2BAA2B;AAC/B","sourcesContent":[".alert {\n    position: fixed;\n    bottom: -50px;\n    left: 50%;\n    transform: translateX(-50%);\n    background-color: #4CAF50;\n    color: white;\n    padding: 15px;\n    border-radius: 10px;\n    transition: transform 0.5s, bottom 0.5s;\n}\n\n.alert.show {\n    bottom: 20px;\n    transform: translateX(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task12() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 12">
          <p>Task 12: Prototype
            <ul>Create a high-fidelity, interactive prototype, incorporating all visual design elements and user interactions to simulate the final product experience. 
                This prototype should facilitate comprehensive user testing and stakeholder feedback, guiding further refinements. Write a summary of your process.
          </ul>
          </p>
       
      </CollapsibleButton>
    </div>
  );
}

export default Task12;

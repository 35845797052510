import React, { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Nav/NavBar';
import NavDashBoard from '../Dashboard/NavDashBoard';

const Projects = () => {
    const [projectsData, setProjectsData] = useState([]);
    // eslint-disable-next-line 
    const [addedProjects, setAddedProjects] = useState([]);
    const navigate = useNavigate();
    const { userId, accessToken, isLoggedIn } = useUser();
   
    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
            return;
        }

        const fetchAvailableProjects = async () => {
            const response = await fetch(`${API_BASE_URL}/projects`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });
            const { data } = await response.json();
            setAddedProjects(data ?? []);
        }

        const fetchProjects = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/user/${userId}/projects`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                const { data } = await response.json();
                console.log("API Response:", data);
                setProjectsData(data.projects);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchAvailableProjects();
        fetchProjects();
    }, [navigate, userId, accessToken, isLoggedIn]);

    const handleAddProjectToDashboard = async (projectTemplateId) => {
        try {
            const response = await fetch(`${API_BASE_URL}/projects`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ projectTemplateId}),
            });
            if (response.ok) {
                const { data } = await response.json();
                setAddedProjects(data);
                alert("Project added to dashboard!");
            } else {
                console.error('Failed to add project to dashboard');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
     


    const renderObjectiveWithLink = (text) => {
        if (text === null) {
            return null;
        }
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        let parts = text.split(regex);
        return parts.map((part, index) => {
            if (index % 3 === 1) {
                return <a href={parts[index + 1]} target="_blank" rel="noopener noreferrer" key={index}>{part}</a>;
            }
            if (index % 3 === 0) {
                return part;
            }
            return null;
        });
    };

    const renderProjectButton = (project, hasActiveProject) => {
        if (hasActiveProject) {
            return (
                <button
                    className='unavailable-button'
                    type="button"
                    disabled
                >
                    Unavailable
                </button>
            );
        }
        return (
            <button
                className='button available-button'
                type="button"
                onClick={() => handleAddProjectToDashboard(project.id)}
            >
                Add to Dashboard
            </button>
        );
    };

    const hasActiveProject = projectsData.some(project => project.user_id && project.review_status === 'N/A');
    const contributedProject = projectsData.map(project => project.template_id);

    return (
        <div>
            <Navbar />
            <h2>All Projects</h2>
            <NavDashBoard />
            <div className="projectcard_container">
                {Array.isArray(addedProjects) && addedProjects?.filter(project => !contributedProject.includes(project.id)).slice(3).map((project) => (
                    <div key={project.id} className="project_card">
                        <img src={project.cover_image} alt='#' />
                        <div className='project_card_content'>
                            <p><strong>Company Overview</strong>
                                <li className='limit-text'>{renderObjectiveWithLink(project.company_overview)}</li>
                            </p>
                            <p>{project.difficulty} | {project.industry}</p>
                            <p><strong>The Problem</strong>
                                <li className='limit-text'>{renderObjectiveWithLink(project.objective)}</li>
                            </p>
                        </div>
                        {renderProjectButton(project, hasActiveProject)}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Projects;

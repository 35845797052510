import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task13() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 13">
      <p>Task 13: User Testing</p>
            <ul>Organize user testing sessions with at least 5 participants. Write 2-4 sentences about your process and discoveries.
                </ul>
            <ul>For Example:
                <li>I interviewed 10 people with varying cryptocurrency experience and found that while seasoned investors praised the tool for its security and self-custody features, 
                    beginners expressed a need for more intuitive navigation and educational content. A common sentiment across the board was the appreciation for real-time analytics, 
                    emphasizing its role in making informed decisions. However, some expressed concerns about initial setup complexity, suggesting a more guided onboarding process. 
                    Overall, the feedback highlighted the tool's potential while also pointing to areas for improvement to cater to a broader user base.
                </li>
          </ul>
       
      </CollapsibleButton>
    </div>
  );
}

export default Task13;

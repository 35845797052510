import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { API_BASE_URL } from '../../constants';
import './SignUp.css';
import { FaEye, FaEyeSlash } from "react-icons/fa";

function SignUp() {
    const { logIn } = useUser();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle confirm password visibility

    async function handleSignUp(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = Object.fromEntries(formData);

        if (data.password !== data.confirmPassword) {
            alert('Passwords do not match');
            return;
        }

        try {
            const response = await fetch(`${API_BASE_URL}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...data }),
            });
            const json = await response.json();
            if (json.success) {
                alert('Sign up successful');

                logIn({
                    token: json.data.token,
                    uId: json.data.user_id,
                });

                navigate('/dashboard');
            } else {
                alert('Sign up failed');
                console.error('Error signing up:', json.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }


    // Toggle function for password
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    // Toggle function for confirm password
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <div className='signup_container'>
            <h1>Sign Up</h1>
            <form onSubmit={handleSignUp}>
                <input
                    placeholder='First Name'
                    type="text"
                    id="firstName"
                    name="firstName"
                    required
                />
                <input
                    placeholder='Last Name'
                    type="text"
                    id="lastName"
                    name="lastName"
                    required
                />
                <input
                    placeholder='Email'
                    type="email"
                    id="email"
                    name="email"
                    required
                />
                <div className="password-container">
                    <input
                        placeholder='Password'
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        required
                    />
                    <button type="button" onClick={toggleShowPassword} className="toggle-password">
                        {showPassword ?  <FaEye />: <FaEyeSlash />}
                    </button>
                </div>
                <div className="password-container">
                    <input
                        placeholder='Confirm Password'
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmPassword"
                        name="confirmPassword"
                        required
                    />
                    <button type="button" onClick={toggleShowConfirmPassword} className="toggle-password">
					{showPassword ?  <FaEye />: <FaEyeSlash />}
                    </button>
                </div>
                <button 
				className='button submit-button'
				type="submit">Sign Up</button>
				<p> Already have an account?<a href="/"> Login Here</a>
                </p>
            </form>
        </div>
    );
}

export default SignUp;
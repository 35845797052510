import React, { useState, useEffect } from 'react';
import './CopyAlert.css';

const Alert = ({ message }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (message) {
            setIsVisible(true);
            const timeout = setTimeout(() => {
                setIsVisible(false);
            }, 2000); // Hide after 5 seconds
            return () => clearTimeout(timeout);
        }
    }, [message]);

    return (
        <div className={`alert ${isVisible ? 'show' : ''}`}>
            {message}
        </div>
    );
};

export default Alert;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapsible {
  margin-bottom: 20px;
}

.collapsible-btn {
  font-family: "League Spartan";
  font-size: 17px;
  background-color: #f1f1f1;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  transition: background-color 0.3s;
}


.collapsible-btn:hover {
  background-color: #ddd;
}

.content {
  padding: 0 18px;
  overflow: hidden;
  background-color: #f1f1f1;
}



.tasks ul{
  color: black;
  padding: inherit;
  font-family: "League Spartan";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding: 0.2px 0px;
}

.tasks li {
  margin-left: 20px
}




.content.show {
  display: block;
}

.arrow {
  display: inline-block;
  margin-right: 10px;
  transition: transform 0.3s;
}

.down {
  transform: rotate(90deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/Tasks/Tasks.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,aAAa;EACb,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,iCAAiC;AACnC;;;AAGA;EACE,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;;;AAIA;EACE,YAAY;EACZ,gBAAgB;EAChB,6BAA6B;EAC7B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE;AACF;;;;;AAKA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".collapsible {\n  margin-bottom: 20px;\n}\n\n.collapsible-btn {\n  font-family: \"League Spartan\";\n  font-size: 17px;\n  background-color: #f1f1f1;\n  color: #444;\n  cursor: pointer;\n  padding: 18px;\n  width: 100%;\n  text-align: left;\n  border: none;\n  transition: background-color 0.3s;\n}\n\n\n.collapsible-btn:hover {\n  background-color: #ddd;\n}\n\n.content {\n  padding: 0 18px;\n  overflow: hidden;\n  background-color: #f1f1f1;\n}\n\n\n\n.tasks ul{\n  color: black;\n  padding: inherit;\n  font-family: \"League Spartan\";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 300;\n  line-height: normal;\n  padding: 0.2px 0px;\n}\n\n.tasks li {\n  margin-left: 20px\n}\n\n\n\n\n.content.show {\n  display: block;\n}\n\n.arrow {\n  display: inline-block;\n  margin-right: 10px;\n  transition: transform 0.3s;\n}\n\n.down {\n  transform: rotate(90deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

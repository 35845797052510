import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task2() {
  return (
    <div className="tasks">
      <CollapsibleButton title="Click here to see directions for: Task 2.">
        <p>Task 2: User Interviews
        <ul> User Interviews: Summarize the findings from your user interviews, highlighting the top 3 insights gathered. 
              Detail how these responses inform our understanding of user needs and preferences, and outline any emerging patterns or themes that could influence our project direction.
              </ul>
        <ul> For Example: In our project's research phase, aimed at enhancing web3 learning experiences, I conducted six user interviews. These insights directly influenced our design decisions, from aesthetics to app functionality.
             </ul>
            <ul> Findings Summary:
            <li>Engagement: Users are open to learning new tech, with the main hurdle being its complexity.</li>
            <li>Intuitive Design: Clear, user-friendly interfaces are crucial for reducing overwhelm.</li>
            <li>Content Tailoring: Adapting the complexity for different expertise levels is essential.</li>
            </ul>
        <ul>These insights underscored the importance of user-centric design to make web3 accessible and engaging for all learners.</ul>
        </p>
      </CollapsibleButton>
    </div>
  );
}

export default Task2;

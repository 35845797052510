import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task1() {
  return (
    <div className='tasks'>
      <CollapsibleButton
      title= "Click here to see directions for: Task 1.">
           <p>Task 1: Secondary Research:
            <ul>Please detail your research process and the insights you've gathered regarding the problem you're addressing in 3-4 sentences.
              <li>This includes: Competitive Analysis, Trend Analysis, and Industry Reports</li>
          </ul>
        <ul>Need more help? 
          <li>Begin by searching for credible online sources that shed light on the issue. In a Google Sheet, systematically document your findings from each source. 
          For every source, include 3-4 bullet points summarizing the critical information, add a direct link to the source for reference, and ensure each entry is clearly labeled. 
          This structured approach will help articulate the problem more clearly and guide your solution development. Doop a summary of this information into the provided field.
          </li>
        </ul>
        <ul>Example: 
          <li>During the discovery phase for the "EcoDrive" app redesign, focused secondary research revealed key insights:
          </li>
          
            <li>Industry Reports highlighted a surge in consumer interest towards eco-friendly driving apps that offer real-time feedback and tips for reducing carbon footprints.</li>
            <li>Competitive Analysis showed successful apps in this space feature gamification and community challenges, areas where "EcoDrive" lacks.</li>
            <li>Trend Analysis indicated a market shift towards personalized, AI-driven sustainability recommendations, suggesting a potential direction for enhancing "EcoDrive."</li>
            
        </ul>
        <ul>This research underscored the need for "EcoDrive" to integrate engaging features and AI personalization to meet market trends and user expectations.</ul> 
        </p>
      </CollapsibleButton>
    </div>
  );
}

//putcoverimage here
export default Task1;

import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task6() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title="Click here to see directions for: Task 6">
      <p> Task 6: User Stories
         <ul>Using the format of “ As a user, i want... so that i can...” write up 2-3 user stories you will be addressing in this project. 
          Label them, hig, med and low priority. Write 2-4 sentences about your process and discoveries.
          </ul>
      <ul>For Example:
            <li>In developing user stories for our Online Language Learning Platform, I synthesized user feedback to identify key needs. 
              The top priorities include personalized lesson plans for targeted learning, brief interactive exercises for learning on-the-go, and multimedia cultural content for deeper engagement. These insights will direct our feature development to better serve our diverse user base.
                </li>
          </ul>
      <ul>High Priority:
            <li>As a language learner, I want to access personalized lesson plans so that I can focus on areas where I need improvement and advance more quickly.
                </li>
      </ul>
      <ul>Medium Priority:
            <li>As a busy professional, I want to engage in short, interactive language exercises during my commute so that I can make efficient use of my time and keep my language skills sharp.
                </li>
      </ul>
      <ul>Low Priority:
            <li>As a cultural enthusiast, I want to access multimedia content related to the languages I'm learning so that I can deepen my understanding of the culture and enhance my learning experience.
                </li>
      </ul>
</p>
      </CollapsibleButton>
    </div>
  );
}

export default Task6;

import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}


function Task3() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title="Click here to see directions for: Task 3">
        <p> Task 3: Affinity Map
         <ul> Create an affinity map to categorize and prioritize information. 
          Write 2-4 sentences about your process and discoveries.
          </ul> 
        <ul>For Example:
          <li>
           In creating an affinity map for our web3 educational platform, I analyzed user interview transcripts to identify recurring themes. 
            I categorized findings into "Learning Challenges," "Feature Preferences," and "Engagement Drivers."
            </li>
            </ul>
        <ul>These insights underscored the importance of user-centric design to make web3 accessible and engaging for all learners.</ul>
        <ul>This approach revealed a user desire for simplified learning pathways and interactive elements to navigate web3 complexities. 
          The map highlighted the appeal for personalized learning experiences, guiding our design strategy to align with user needs and preferences.</ul>
          </p>
      </CollapsibleButton>
    </div>
  );
}

export default Task3;

import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}


function Task4() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 4">
        <p>Task 4: Empathy Map
        <ul> 
          Craft an empathy map to deepen our understanding of your users, aiming to tailor your product to their real needs and emotions.
          Write 2-4 sentences about your process and discoveries.
          </ul>

        <ul>Need more help? 
          <li>
            <ul>Preparation: 
              <li>Review user research data to familiarize yourself with user feedback and observations. Set up your workspace with four quadrants labeled: Says, Thinks, Does, Feels.
              </li>
              </ul>
              </li>
        </ul>

        <ul>Filling the Quadrants:
          <li>
            <ul>Says: 
              <li>Note direct quotes from users about their learning experiences with web3.</li>
            </ul>
          </li>

          <li>
            <ul>Thinks:
              <li>Note direct quotes from users about their learning experiences with web3. </li>
              </ul>
          </li>
            
          <li>
            <ul>Does:
              <li>Document observable actions users take to learn or apply web3 technologies. </li>
              </ul>
          </li>

          <li>
            <ul>Feels:
              <li>Capture the emotions users express related to their web3 learning journey. </li>
              </ul>
          </li>
        </ul>
    
        <ul>Analysis:
            <li>Look for patterns, contradictions, or unique insights across all quadrants.</li>
            <li>Discuss these findings with your team to understand the user's perspective deeply.</li>
        </ul>
        <ul>Actionable Insights:
            <li>Based on your empathy map, identify key user needs, pain points, and emotional drivers.</li>
            <li>Translate these insights into actionable design strategies that address user challenges and enhance their learning experience.</li>
        </ul>
        <ul>Example:
            <li>Says: "It's hard to find resources that explain web3 in simple terms." </li>
            <li>Thinks: "Maybe web3 is too complex for me."</li>
            <li>Does: Frequently visits forums and watches lengthy tutorials but struggles to apply concepts.</li>
            <li>Feels: Frustrated with the steep learning curve but remains curious about the potential of web3.</li>
        </ul>
        </p>
      </CollapsibleButton>
    </div>
  );
}

export default Task4;

import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task10() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 10">
      <p>Task 10: Wire Frames</p>
            <ul>Create low and mid-fidelity wireframes for the interface using Figma. Write up a short summary of your process and explain the reasoning behind your design decisions. Mock up the wireframes.
                </ul>
            <ul>For Example:
                <li>In designing a mobile library app, we started with low-fidelity wireframes in Figma to outline basic app functions like browsing and reserving books. 
                  Transitioning to mid-fidelity, we added detail such as button styles and a preliminary color scheme, refining the design based on user feedback to ensure intuitive navigation and readability. This iterative process, grounded in early user testing, was crucial for aligning the app's design with user needs and expectations.
                </li>
          </ul>
      </CollapsibleButton>
    </div>
  );
}

export default Task10;

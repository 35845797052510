import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../../constants';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Nav/NavBar';
import NavDashBoard from '../Dashboard/NavDashBoard';


const Completed = () => {
    const [projectsData, setProjectsData] = useState([]);
     // eslint-disable-next-line
    const [addedProjects, setAddedProjects] = useState(new Set());
    const navigate = useNavigate();
    const { userId, accessToken, isLoggedIn } = useUser();

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
            return;
        }

        const fetchProjects = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/user/${userId}/projects`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
                const { data } = await response.json();
                console.log("API Response:", data);
                setProjectsData(data.projects);
            } catch (error) {
                console.error('Error:', error);
            }
        };
        fetchProjects();
    }, [navigate, userId, accessToken, isLoggedIn]);

    const handlePostProject = async (id, message) => {
        try {
            const response = await fetch(`${API_BASE_URL}/projects`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify({ projectTemplateId: id, problemStatement: message }),
            });
            if (response.ok) {
                console.log(`${id} projectID posted`);
                setAddedProjects(prev => new Set(prev).add(id));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const renderObjectiveWithLink = (text) => {
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        let parts = text.split(regex);
        return parts.map((part, index) => {
            if (index % 3 === 1) {
                return <a href={parts[index + 1]} target="_blank" rel="noopener noreferrer" key={index}>{part}</a>;
            }
            if (index % 3 === 0) {
                return part;
            }
            return null;
        });
    };

    return (
        <div>
            <Navbar />
            <h2>Completed Sprints</h2>
            <NavDashBoard />
            <div className="projectcard_container">
                {projectsData.filter(project => project.review_status === 'Requested' || project.review_status === 'Complete').map((project, index) => ( 
                    <div key={index} className="project_card">
                        <img src={project.project_template.cover_image} alt='cover' />
                        <div className='project_card_content'>
                        <p><strong>Company Overview</strong>
                                <li className='limit-text'>{renderObjectiveWithLink(project.project_template.company_overview)}</li>
                            </p>
                            <p><strong>The Problem</strong>
                                <li className='limit-text'>{renderObjectiveWithLink(project.project_template.objective)}</li>
                            </p>
                            <p>{project.project_template.difficulty} | {project.project_template.industry}</p>
                        </div>
                        <button
                            className='completed-button'
                            type="button"
                            onClick={() => {
                                console.log(`posting this project id: ${project.id}`)
                                handlePostProject(project.id, 'Project Added')
                            }}
                            disabled = {true} // Add the disabled attribute to make the button disabled
                        >Completed</button>

                    </div>
                ))}
            </div>
        </div>
    );
};

export default Completed;

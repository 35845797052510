import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../constants';
import { useUser } from '../../contexts/UserContext';
import './Dashboard.css';
import Navbar from '../Nav/NavBar';
import Lio1 from '../Lio1/Lio1'
import NavDashBoard from './NavDashBoard';

function Dashboard() {
    const navigate = useNavigate();
    const { userId, accessToken, isLoggedIn } = useUser();
    const [userName, setUserName] = useState(null);
    const [loadingProjects, setLoadingProjects] = useState(true);
    const [project, setProject] = useState(null);

    const urlParams = new URLSearchParams(window.location.search);
    const resetToken = urlParams.get('reset-token');
    console.log(resetToken);

    const fetchUser = useCallback(async (id) => {
        try {
            const response = await fetch(`${API_BASE_URL}/user/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const json = await response.json();
            const { first_name, last_name } = json.data;
            setUserName(`${first_name} ${last_name}`);
        } catch (error) {
            console.error('Error:', error);
        }
    }, [userId, accessToken]);

    const fetchProjects = useCallback(async () => {
        try {
            console.log('fetching projects');
            const response = await fetch(`${API_BASE_URL}/user/${userId}/projects`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            const { data } = await response.json();
            const { projects } = data;
            console.log(projects);
            setLoadingProjects(false);

            const filteredProjects = projects.filter(project => project.user_id && (project.review_status === 'N/A' || project.review_status === null));

            if (filteredProjects.length > 0) {
                // If the user has projects, set the first project as the current project
                setProject(filteredProjects[0]);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }, [userId, accessToken]);

    useEffect(() => {
        if (!isLoggedIn) {
            navigate('/');
        } else {
            fetchUser();
            fetchProjects();
        }
        // eslint-disable-next-line
    }, [navigate, accessToken, isLoggedIn, userId]);
    
    

    const handleProjectClick = (projectId) => {
        navigate(`/casestudy/${projectId}`);
    };

	const renderTextWithLink = (text) => {
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        let parts = text.split(regex);
        let elements = [];
        for (let index = 0; index < parts.length; index += 3) {
            if (parts[index + 1] && parts[index + 2]) {
                elements.push(
                    <a href={parts[index + 2]} target="_blank" rel="noopener noreferrer" key={index}>
                        {parts[index + 1]}
                    </a>
                );
            }
        }
        return elements;
    };


    const renderObjectiveWithLink = (text) => {
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        let parts = text.split(regex);
        return parts.map((part, index) => {
            if (index % 3 === 1) {
                return <a href={parts[index + 1]} target="_blank" rel="noopener noreferrer" key={index}>{part}</a>;
            }
            if (index % 3 === 0) {
                return part;
            }
            return null;
        });
    };

    if (!isLoggedIn) {
        return null; // or redirect to login page
    }

    return (
        <div>
            <Navbar />
            <h2>Welcome to your dashboard, {userName}</h2>
            <NavDashBoard/>
            {loadingProjects ? (
                <p>Loading...</p>
            ) : (
                <div className='projectcard_container'>
                    {project ? (
                        <div key={project.id} className='project_card'>
                            <div>
                                <img src={project.project_template.cover_image} alt='cover'/>
                                <div className='project_card_content'>
                                    <p className='project_title'>{project.project_template.title}</p>
                                    <p><strong>Client: </strong> {renderTextWithLink(project.project_template.company_overview)}</p>
                                    <p ><strong>Objective:</strong> 
                                   <li className='limit-text'>{renderObjectiveWithLink(project.project_template.objective)}</li>
                                    </p>
                                    <p>{project.project_template.difficulty} | {project.project_template.industry}</p>
                                </div>
                            </div>
                            <div className='button-progress'>
                                
                                <button
                                    className='button start-button'
                                    type="button"
                                    onClick={() => handleProjectClick(project.id)}>Start</button>
                            </div>
                        </div>
                    ) : (
                        <h2>No projects found</h2>
                    )}
                </div>
            )}
            <Lio1 isOpen={true}/>
        </div>
    );
}

export default Dashboard;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    position: fixed;
    top: 0;
    left: 0; /* Explicitly set to make sure navbar starts at the very left */
    width: 100%;
    background-color: #20263B;
    z-index: 1000;

    }

    .navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 20px;
    text-decoration: none;
    font-family: 'League Spartan', sans-serif;

    }

    .navbar a:hover {
    background-color: #ddd;
    color: black;
    }`, "",{"version":3,"sources":["webpack://./src/components/Nav/NavBar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO,EAAE,+DAA+D;IACxE,WAAW;IACX,yBAAyB;IACzB,aAAa;;IAEb;;IAEA;IACA,WAAW;IACX,cAAc;IACd,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,qBAAqB;IACrB,yCAAyC;;IAEzC;;IAEA;IACA,sBAAsB;IACtB,YAAY;IACZ","sourcesContent":[".navbar {\n    display: flex;\n    position: fixed;\n    top: 0;\n    left: 0; /* Explicitly set to make sure navbar starts at the very left */\n    width: 100%;\n    background-color: #20263B;\n    z-index: 1000;\n\n    }\n\n    .navbar a {\n    float: left;\n    display: block;\n    color: #f2f2f2;\n    text-align: center;\n    padding: 14px 20px;\n    text-decoration: none;\n    font-family: 'League Spartan', sans-serif;\n\n    }\n\n    .navbar a:hover {\n    background-color: #ddd;\n    color: black;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

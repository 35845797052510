import React, { useState } from 'react';
import './Tasks.css';
import { IoCaretForward } from "react-icons/io5";

function CollapsibleButton({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <button className="collapsible-btn" onClick={toggleCollapse}>
        <IoCaretForward className={isOpen ? "arrow down" : "arrow"} /> {title}
      </button>
      {isOpen && (
        <div className="content">
          {children}
        </div>
      )}
    </div>
  );
}

function Task8() {
  return (
    <div className="tasks">
      
      <CollapsibleButton title= " Click here to see directions for: Task 8">
      <p>Task 8: Mood Board</p>
      <ul> Use this template to create a mood board for this project. Find imagery and UI inspiration that embodies the brand you are creating. Write up some bullet points explaining your choice for the mood board and brand personality. Write 2-4 sentences about your process.
                </ul>
            <ul>For Example:
            <ul>
            <li>Mood Board Choices:
                <li>Modern Design: Reflects the cutting-edge nature of crypto.</li>
                <li>Cool Colors: Evokes trust and stability.</li>
                <li>Tech Imagery: Highlights the product's tech foundation.</li>
            </li>
            <li>Brand Personality Choices:
                <li>Trustworthy: Ensures users of asset security.</li>
                <li>Innovative: Stays ahead in the crypto evolution.</li>
                <li>User-Centric: Prioritizes user needs and experience.</li>
            </li>
            </ul>
          </ul>
      </CollapsibleButton>
    </div>
  );
}

export default Task8;